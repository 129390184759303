import { firebaseFunctionDispatcher } from 'firebase.js';
const isProduction = process.env.NODE_ENV === 'production';
const project = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const token = process.env.REACT_APP_PAPERTRAIL_TOKEN;
if (!token) console.error('Papertrail token is missing!');

export const log = (message, metadata) => logHelper('info', message, metadata);
export const logWarn = (message, metadata) => logHelper('warn', message, metadata);
export const logError = (message, metadata) => logHelper('error', message, metadata);
export const logDivider = () => logHelper('divider', '-'.repeat(125));

const logHelper = (level, msg, metadata = {}) => {
  const isLog = level !== 'divider';

  // log locally
  if (isLog) {
    console[level](msg, ...(Object.keys(metadata).length ? [metadata] : []));
  }

  // log to Papertrail (production only)
  if (isProduction && token) {
    const { email, order, error, userAgent, ...rest } = metadata;
    const includeLevel = level === 'warn' || level === 'error';
    const includeMetadata = Object.keys(rest).length > 0;

    const payload = {
      ...includeLevel ? { level } : {},
      msg,
      ...isLog && { timestamp: new Date().toISOString() },
      ...email && { email },
      ...userAgent && { userAgent },
      ...order && { order },
      ...error && { error },
      ...includeMetadata && { metadata: rest },
      ...isLog && { project }
    };
    logToPapertrail(payload);
  }
};

const logToPapertrail = async (payload) => {
  try {
    await fetch('https://logs.collector.solarwinds.com/v1/log', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Basic ' + btoa(`:${token}`) },
      body: JSON.stringify(payload)
    });
  } catch (error) {
    // if browser blocks client-side request, route via cloud function
    firebaseFunctionDispatcher({ action: 'logToPapertrail', data: payload });
  }
};
