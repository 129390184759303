import { List, ListItem } from '@mui/material';
import { StyledLink, StyledPaper, PageTitle, Header, Paragraph } from 'components/Layout/SharedStyles';
import { Photo } from 'components/Layout/Photo';

export default function Schedule() {
  return (
    <StyledPaper extraStyles={{ maxWidth: 750 }}>
      <PageTitle>
        Itinerary
      </PageTitle>

      <Photo filename='instruments.jpg' />

      <Header>
        Supersonic Contra Dance Weekend Itinerary
      </Header>
      <List>
        <ListItem>2025 &mdash;&nbsp;<StyledLink to={process.env.PUBLIC_URL + '/supersonic/FlightItinerary2025.pdf'}>Supersonic Contra Dance Weekend Itinerary</StyledLink></ListItem>
      </List>

      <Paragraph>
        Consider extending your dance week!
      </Paragraph>
        <ul>
          <li><StyledLink to='https://joyride.erikweberg.com/schedule'>Wed Feb 5 in Portland &mdash; Joyride with Seth Tepfer</StyledLink></li>
          <li><StyledLink to='https://seattledance.org/contra/lakecity'>Thu Feb 6 in Seattle &mdash; Potato Factory with Seth Tepfer</StyledLink></li>
        </ul>

      <Header>
        Past Flights
      </Header>
      <List>
        <ListItem>2024 &mdash; Notorious and Playing with Fyre with Seth Tepfer and Will Mentor</ListItem>
        <ListItem>2023 &mdash; No dance weekend</ListItem>
        <ListItem>2022 &mdash; No dance weekend</ListItem>
        <ListItem>2021 &mdash; No dance weekend</ListItem>
        <ListItem>2020 &mdash; Elixir and the Syncopaths with Nils Fredland and Seth Tepfer</ListItem>
        <ListItem>2019 &mdash; Supertrad and The Dam Beavers and Cis Hinkle and Seth Tepfer</ListItem>
        <ListItem>2018 &mdash; Genticorum and Contra Sutra with callers Seth Tepfer and Deb Comly</ListItem>
      </List>
    </StyledPaper>
  );
}
